import { ColumnComponent } from '../flow/Column';
import { LineComponent } from '../flow/Line';
import { listConvertFunc } from '../overview/listConvertFunc';

const ViewConfig: {
	title: string;
	component: typeof LineComponent | typeof ColumnComponent;
	yField: keyof ReturnType<typeof listConvertFunc>[0];
}[] = [
	{
		title: '电表度数',
		yField: 'electricMeterReadingThisMonth',
		component: LineComponent,
	},
	{
		title: '电表用量',
		yField: 'totalElectricUsed',
		component: ColumnComponent,
	},
	{
		title: '冷水度数',
		yField: 'coldWaterMeterReadingThisMonth',
		component: LineComponent,
	},
	{
		title: '冷水用量',
		yField: 'totalColdWaterUsed',
		component: ColumnComponent,
	},
	{
		title: '热水度数',
		yField: 'hotWaterMeterReadingThisMonth',
		component: LineComponent,
	},
	{
		title: '热水用量',
		yField: 'totalHotWaterUsed',
		component: ColumnComponent,
	},
	{
		title: 'rent trend',
		yField: 'rent',
		component: LineComponent,
	},
];
export function UsedTrendView({
	roomLastInfoList,
}: {
	roomLastInfoList: ReturnType<typeof listConvertFunc>;
}) {
	return (
		<>
			<div className="flex-1 grid grid-cols-2 gap-2 p-2">
				{ViewConfig.map((item) => {
					return (
						<div key={item.title}>
							<div className="text text-lg">{item.title}</div>
							{
								<item.component
									{...{
										height: 100,
										xField: 'checkInTimeFormat',
										yField: item.yField,
										data: roomLastInfoList?.map((i) => {
											return {
												checkInTimeFormat: i.checkInTimeFormat,
												[item.yField]: i[item.yField],
											};
										}),
										meta: {
											totalElectricUsed: {
												alias: '当月用电量',
											},
											totalColdWaterUsed: {
												alias: '当月冷水量',
											},
											totalHotWaterUsed: {
												alias: '当月热水量',
											},
										},
									}}
								/>
							}
						</div>
					);
				})}
			</div>
		</>
	);
}
